import { HttpClientBaseMehod } from "@/enums/api/http-base-method";
import { HttpClientBaseHeader } from "@/interfaces/api/HttpClientHeader";
import { HttpClientBaseStatusCode } from "@/interfaces/api/HttpClientStatusCode";
import { HttpStatusCode } from "@/enums/api/http-status-code";
import notify from "devextreme/ui/notify";
import i18n from "@/i18n";
import { GrantType } from "@/enums/auth/GrantType";
import { AuthResponse } from "@/interfaces/auth/AuthResponse";
import { router } from "@/router";

export class HttpClientBase {
  private resp: any;
  private respBody: any;
  private base_url: string;

  constructor() {
    this.base_url = process.env.VUE_APP_BASE_URL;
  }

  async createRequest(
    url: string,
    method: HttpClientBaseMehod,
    httpHeader?: HttpClientBaseHeader,
    requestData?: any,
    isNotStringified?: boolean,
    isFileDownload?: boolean,
  ): Promise<this> {
    const requestInit: RequestInit = {
      method,
    };

    if (httpHeader) {
      requestInit.headers = httpHeader as any;
    }

    const token = localStorage.getItem("access_token");
    const tokenExpiration = localStorage.getItem("token_expiration");
    if (token && tokenExpiration) {
      const expirationToken = Date.parse(tokenExpiration);

      if (expirationToken > Date.now()) {
        requestInit.headers = {
          ...requestInit.headers,
          Authorization: `Bearer ${token}`,
        };
      } else {
        if (this.refresh()) {
          const token = localStorage.getItem("access_token");
          requestInit.headers = {
            ...requestInit.headers,
            Authorization: `Bearer ${token}`,
          };
        } else {
          await router.push({ path: "/login" });
          this.logout();
          return this;
        }
      }
    }

    if (requestData) {
      if (isNotStringified) {
        requestInit.body = requestData;
      } else {
        requestInit.body = JSON.stringify(requestData);
      }
    }

    this.resp = await fetch(`${this.base_url}/${url}`, requestInit);

    if (
      method === HttpClientBaseMehod.PATCH ||
      method === HttpClientBaseMehod.DELETE
    ) {
      this.respBody = {};
      return this;
    }

    if (isFileDownload) {
      this.respBody = await this.resp.blob();
    } else {
      this.respBody = await this.resp.json();
    }

    const statusCode = this.statusCode();

    if (statusCode.statusCode >= 400) {
      const { t } = i18n().global;
      notify(
        t(this.respBody.TranslationKey ?? this.respBody.Message),
        //i18n().t(this.respBody.TranslationKey ?? this.respBody.Message),
        "error",
        3000,
      );
    }

    return this;
  }

  refresh() {
    const refreshTokenStr = localStorage.getItem("refresh_token");
    const refreshTokenExpiration = localStorage.getItem("refresh_expiration");
    const refreshExp = Date.parse(refreshTokenExpiration as string);

    if (refreshExp < Date.now()) {
      return false;
    }

    const request = new XMLHttpRequest();
    request.open(
      "POST",
      `${process.env.VUE_APP_BASE_URL}/authorization/token`,
      false,
    );
    request.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded",
    );

    const tenantId = localStorage.getItem("tenantId");

    if (tenantId) {
      request.setRequestHeader("TenantIdentifier", tenantId);
    }

    const refreshRequest: URLSearchParams = new URLSearchParams({
      GrantType: GrantType.refresh_token,
      RefreshToken: refreshTokenStr as string,
    });

    request.send(refreshRequest);

    if (request.status === 200) {
      const authResponse: AuthResponse = JSON.parse(request.response);
      this.setAuthResponseToLocalStorage(authResponse);

      return true;
    }

    return false;
  }

  setAuthResponseToLocalStorage(response: AuthResponse) {
    localStorage.setItem("access_token", response.AccessToken as string);
    localStorage.setItem(
      "token_expiration",
      response.AccessTokenExpiresUtc as string,
    );
    localStorage.setItem("refresh_token", response.RefreshToken as string);
    localStorage.setItem(
      "refresh_expiration",
      response.RefreshTokenExpiresUtc as string,
    );
    localStorage.setItem("user_id", response.UserId as string);
    localStorage.setItem("tenant_id", response.TenantId as string);
  }

  statusCode(): HttpClientBaseStatusCode {
    const statusCode = this.resp.status as HttpStatusCode;
    const statusText = HttpStatusCode[
      this.resp.status
    ] as unknown as HttpStatusCode;

    return {
      statusCode,
      statusText,
    };
  }

  logout(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_expiration");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_expiration");
    localStorage.removeItem("user_id");
    localStorage.removeItem("tenantId");
  }

  isOk(): boolean {
    return this.resp.ok;
  }

  responseByKey<T>(key: string | null = null): T {
    if (key) {
      return this.respBody[key];
    } else {
      return this.respBody;
    }
  }

  responseData<T>(): T {
    return this.respBody.data;
  }
}
