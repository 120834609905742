import { HttpService } from "@/services/base/api/HttpService";
import { User } from "@/interfaces/user/User";

export default class AuthService {
  isUserLoggedIn(): boolean {
    const token = localStorage.getItem("access_token");
    const tokenExpiration = localStorage.getItem("token_expiration");
    if (token && tokenExpiration) {
      const expirationToken = Date.parse(tokenExpiration);

      if (expirationToken > Date.now()) {
        return true;
      } else {
        const httpService = new HttpService();
        if (httpService.refresh()) {
          return true;
        }
      }
    }

    this.logout();
    return false;
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem("user") as string);
  }

  getUserRoles(): number[] {
    const rolesFromLocalStorage: string[] = JSON.parse(
      localStorage.getItem("user_roles") as string,
    );

    const mappedRoles: number[] = [];

    rolesFromLocalStorage.forEach((role: string) => {
      mappedRoles.push(Number(role));
    });

    return mappedRoles;
  }

  logout(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_expiration");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_expiration");
    localStorage.removeItem("user_id");
    localStorage.removeItem("tenantId");
  }
}
