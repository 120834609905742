import { HttpClientBase } from "@/services/base/api/http-base";
import { HttpClientBaseHeader } from "@/interfaces/api/HttpClientHeader";
import { HttpClientBaseMehod } from "@/enums/api/http-base-method";
import { HttpApiJsonResponse } from "@/interfaces/api/HttpResponse";
import { AuthRequest } from "@/interfaces/auth/AuthRequest";
import { AuthResponse } from "@/interfaces/auth/AuthResponse";
import { User } from "@/interfaces/user/User";
import { GrantType } from "@/enums/auth/GrantType";

export class HttpService extends HttpClientBase {
  private header: HttpClientBaseHeader;
  constructor() {
    super();
    this.header = {
      "Content-Type": "application/json",
    };

    const tenantId = localStorage.getItem("tenantId");
    if (tenantId) {
      this.header = { ...this.header, TenantIdentifier: tenantId };
    }
  }

  async login(authRequest: AuthRequest, tenant?: string): Promise<boolean> {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const loginResponse: HttpApiJsonResponse<AuthResponse> =
      await this.post<any>(
        "authorization/token",
        new URLSearchParams({
          Username: authRequest.Username as string,
          Password: authRequest.Password as string,
          GrantType: GrantType.password,
        }),
        true,
        tenant,
        headers,
      );

    if (loginResponse.isOk) {
      this.setAuthResponseToLocalStorage(loginResponse.data);

      if (await this.getUser()) {
        return true;
      }
    }

    return false;
  }

  async getUser(): Promise<boolean> {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      const token = localStorage.getItem("access_token");
      this.header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const tenant = localStorage.getItem("tenantId");

      if (tenant) {
        this.header = { ...this.header, TenantIdentifier: tenant };
      }

      const user: HttpApiJsonResponse<User> = await this.get<any>(
        "authorization/userinfo",
      );

      if (user.isOk) {
        localStorage.setItem("user", JSON.stringify(user.data));
        localStorage.setItem(
          "user_roles",
          JSON.stringify(
            this.mapAndSetUserRoles(user.data.AllowedPermissionIds as number[]),
          ),
        );

        return true;
      }
    }

    return false;
  }

  async get<T>(
    url: string,
    isFileDownload?: boolean,
  ): Promise<HttpApiJsonResponse<T>> {
    const request = await this.createRequest(
      url,
      HttpClientBaseMehod.GET,
      this.header,
      null,
      false,
      isFileDownload,
    );

    return {
      data: request.responseByKey<T>(),
      isOk: request.isOk(),
    };
  }

  async post<T>(
    url: string,
    requestData: any,
    isNotStringified = false,
    tenant?: string,
    headers?: object,
    isFileUpload = false,
  ): Promise<HttpApiJsonResponse<T>> {
    if (tenant) {
      this.header = { ...this.header, TenantIdentifier: tenant };
    }

    if (isFileUpload) {
      const newHeaders: HttpClientBaseHeader = {
        Authorization: this.header.Authorization,
        TenantIdentifier: this.header.TenantIdentifier,
      };
      this.header = newHeaders;
    }

    if (headers) {
      this.header = Object.assign(this.header, headers);
    }

    const request = await this.createRequest(
      url,
      HttpClientBaseMehod.POST,
      this.header,
      requestData,
      isNotStringified,
    );

    return {
      data: request.responseByKey<T>(),
      isOk: request.isOk(),
    };
  }

  async put<T>(url: string, requestData: any): Promise<HttpApiJsonResponse<T>> {
    const request = await this.createRequest(
      url,
      HttpClientBaseMehod.PUT,
      this.header,
      requestData,
    );

    return {
      data: request.responseByKey<T>(),
      isOk: request.isOk(),
    };
  }

  async patch<T>(
    url: string,
    requestData: any,
  ): Promise<HttpApiJsonResponse<T>> {
    const request = await this.createRequest(
      url,
      HttpClientBaseMehod.PATCH,
      this.header,
      requestData,
    );

    return {
      data: request.responseByKey<T>(),
      isOk: request.isOk(),
    };
  }

  async delete<T>(url: string): Promise<HttpApiJsonResponse<T>> {
    const request = await this.createRequest(
      url,
      HttpClientBaseMehod.DELETE,
      this.header,
    );

    return {
      data: request.responseByKey<T>(),
      isOk: request.isOk(),
    };
  }

  mapAndSetUserRoles(roles: number[]): string[] {
    const mappedRoles: string[] = [];

    roles.forEach((role) => {
      mappedRoles.push(role.toString());
    });

    return mappedRoles;
  }
}
